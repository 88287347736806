<template>
    <div class="app-container full-height" ref="app-con">
        <router-view></router-view>
        <base-modal v-if="modal.open" @close-modal="closeModal" :key="modal.name"
            :hasCloseButton="modal.hasCloseButton" :alignCenter="modal.alignCenter"
            :forceMobile="modal.forceMobile" :extraClasses="modal.extraClasses"
            :canCloseBackDrop="modal.canCloseBackDrop" :layoutType="modal.layoutType"
            :mobileBreakpoint="modal.mobileBreakpoint" :onClose="modal.onClose" ref="base-modal">
            <component :is="modal.name" v-bind="modal.props" @close-modal="closeModal"></component>
        </base-modal>
        <notify-pop-up v-if="openNotifPopUp"></notify-pop-up>
        <discount-pop-up v-if="openDiscountPopUp"></discount-pop-up>
        <user-notif ref="user-notif"></user-notif>
        <component :is="dynamicComponent.name" v-bind="dynamicComponent.props" v-if="dynamicComponent"></component>
    </div>
</template>

<script>
    import {checkNull, safeRedirect} from '@/helpers'
    import cookies_app from "@views/common/CookiesSettingsModal/cookies_app"
    import promotionMixin from '@/common/promotion'
    import camsMixin from '@/common/cams'

    export default {
        name: 'app',
        mixins: [cookies_app, promotionMixin, camsMixin],
        metaInfo() {
            let meta = {
                title: this.title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.description },
                    { vmid: 'keywords', name: 'keywords', content: this.keywords }
                ]
            }
            if (window.APP_CONFIG.CANONICAL_URL) {
                let url = window.APP_CONFIG.CANONICAL_URL;
                let pattern = new RegExp('^(https?|ftp)://');
                if (!pattern.test(url)) {
                    url = window.location.origin + '/' + url.replace(/^\//g, '');
                }
                meta.link = [{ vmid: 'canonical', rel: 'canonical', href: url }];
            }
            return meta;
        },
        data() {
            return {
                icdn_url: window.APP_CONFIG.ICDN_URL,
                referer: window.APP_CONFIG.REFERER,
                is_dev: process.env.NODE_ENV !== 'production',
                title: '',
                description: '',
                keywords: '',
                loading: false,
                modal: {
                    open: false,
                    name: '',
                    hasCloseButton: false,
                    alignCenter: false,
                    canCloseBackDrop: true,
                    props: {}
                },
                dynamicComponent: null,
                width: window.innerWidth
            }
        },
        methods: {
            changeModalContent({name, hasCloseButton, alignCenter, canCloseBackDrop, layoutType, forceMobile, extraClasses, mobileBreakpoint, onClose, props}) {
                this.modal.name = name
                this.modal.hasCloseButton = hasCloseButton
                this.modal.alignCenter = alignCenter
                this.modal.canCloseBackDrop = canCloseBackDrop
                this.modal.layoutType = layoutType
                this.modal.forceMobile = forceMobile
                this.modal.extraClasses = extraClasses
                this.modal.mobileBreakpoint = mobileBreakpoint
                this.modal.onClose = onClose
                this.modal.props = props
            },
            openModal() {
                this.$refs['app-con'].classList.add('modal-open')
                this.modal.open = true
            },
            closeModal() {
                this.$refs['app-con'].classList.remove('modal-open')
                this.modal.open = false
            },
            setDynamicComponent(component) {
                this.dynamicComponent = component
            },
            handleResize(e) {
                this.width = e.target.innerWidth
            },
            setMetas(route) {
                this.title = route.meta.title
                this.description = route.meta.description
                this.keywords = route.meta.keywords
            },
            redirectWebCamUrl() {
                this.debounceWebCamRedirect()
            },
            resetDataLoaded() {
                this.$store.dispatch('profile/populateProfiles')
                this.$store.dispatch('pub/getFormValues')
                this.$store.dispatch('pub/getCityPages')
            },
            modifyCookies() {
                if(['google', 'bing'].indexOf(this.referer) !== -1) {
                    let sd_config = this.$store.getters['cookies/getSdConfig']
                    let append_config = {}
                    append_config.s1 = this.referer
                    append_config.s2 =  window.location.hostname
                    sd_config = {...sd_config, ...append_config}
                    this.$store.dispatch('cookies/setSdConfig', sd_config);
                }
            },
            checkIfInactiveUser() {
                // list of routes that should not show this modal
                let a_routes = ['user-credits', 'user-premium', 'user-settings', 'support']
                let dont_show = (a_routes.indexOf(this.$route.name) !== -1)
                if(this.isInActive && !dont_show) {
                    // add check if user is inactive
                    console.log('open modal inactive user')
                    this.changeModalContent({
                        name: 'inactive-user-pop-up',
                        hasCloseButton: false,
                        alignCenter: true,
                        canCloseBackDrop: false,
                        layoutType: "medium",
                        extraClasses: "inactive-user"
                    })
                    this.openModal()
                } else {
                    if (this.modal.name === 'inactive-user-pop-up') this.closeModal()
                    if (this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/trialEnded'] && ['user-activate-profile'].indexOf(this.$route.name) === -1) {
                        this.$store.dispatch('trial/end', (params) => {
                            this.$router.push({name: 'user-activate-profile', params})
                        })
                    }
                }
            },
            handleLinks(e) {
                let { target } = event
                while (target && target.tagName !== 'A') target = target.parentNode
                // handle only links that do not reference external resources
                if (target && target.href) {
                    this.$store.commit('pub/setRouteTo', target.href)
                }
            },
            openCompleteRegister() {
                this.changeModalContent({
                    name: 'complete-register-pop-up',
                    hasCloseButton: true,
                    alignCenter: true,
                    canCloseBackDrop: false,
                    layoutType: "medium",
                    extraClasses: "small-header",
                    mobileBreakpoint: 1199,
                    onClose: () => {
                        this.$store.dispatch("auth/denyCompleteRegister").then(() => {
                            this.$store.dispatch("auth/getUser")
                        })
                    }
                })
                this.openModal()
            },
            openBoostAccount() {
                this.changeModalContent({
                    name: 'boost-account-pop-up',
                    hasCloseButton: true,
                    alignCenter: true,
                    canCloseBackDrop: false,
                    layoutType: "flex",
                    mobileBreakpoint: 1199,
                    onClose: () => {
                        this.$store.dispatch("auth/denyBoostProfile").then(() => {
                            this.$store.dispatch("auth/getUser")
                        })
                    }
                })
                this.openModal()
            },
            addHtmlClass(isAdd, elm, className) {
                isAdd ? document.querySelector(elm).classList.add(className) : document.querySelector(elm).classList.remove(className)
            },
            disableParentScrolls(param) {
                if(!param) {
                    this.addHtmlClass(false, 'body', 'scroll-overlay-host')
                    this.addHtmlClass(false, 'html', 'scroll-overlay-host')
                } else {
                    this.addHtmlClass(true, 'body', 'scroll-overlay-host')
                    this.addHtmlClass(true, 'html', 'scroll-overlay-host')
                }
            },
        },
        created() {
            window.addEventListener("click", this.handleLinks)
            window.addEventListener("resize", this.handleResize)
            this.$on('open-modal', this.openModal)
            this.$on('close-modal', this.closeModal)
            //fix for ios10 and below devices rendering
            this.$nextTick(() => {
                window.scrollTo(0, 1)
                window.scrollTo(0, 0)
            })
            window.onbeforeunload = () => {
                if (!this.$store.getters['pub/allowLeave']) {
                    if(!this.isGoingExternal) return;
                    return 'Are you sure you wish to leave?';
                    // not what actually gets displayed in most browsers
                }
            }
        },
        beforeDestroy() {
            window.removeEventListener("click", this.handleLinks)
            window.removeEventListener("resize", this.handleResize)
            this.$off('open-modal', this.openModal)
            this.$off('close-modal', this.closeModal)
        },
        computed: {
            isInActive() {
                return Boolean(this.$store.getters['auth/profile']['inactive'])
            },
            openNotifPopUp() {
                return this.$store.getters['auth/isAuthenticated'] && !this.$store.getters['auth/webPushInitialized']
                    && this.$store.getters['auth/openNotifPopUp'] && !this.$store.getters['auth/isOnTrial']
            },
            isGoingExternal() {
                const routeTo = this.$store.getters['pub/routeTo']
                let fromInside = checkNull(routeTo) && routeTo.indexOf(window.location.hostname) !== -1
                return !fromInside
            },
            openDiscountPopUp() {
                return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['payment/openDiscountPopUp']
            }
        },
        mounted() {
            this.setMetas(this.$route)
            if(this.$store.getters['auth/isAuthenticated']) {
                this.$store.dispatch('auth/getUser', {
                    success_cb: (res) => {
                        this.checkIfInactiveUser();
                    }
                })
                this.$store.dispatch('message/getIcebreakers')
            }
            this.resetDataLoaded()
        },
        watch: {
            '$route' (to, from) {
                this.setMetas(to)
                this.$refs['app-con'].classList.remove('modal-open')
                if(!this.isInActive && this.modal.open && typeof(this.$refs['base-modal']) != 'undefined') this.$refs['base-modal'].closeModal()
                if(checkNull(to.params.fromRemoveAuth)) this.resetDataLoaded()
                this.checkIfInactiveUser();
            },

            modal: {
                handler(newVal) {
                   document.body.style.overflow = newVal.open ? 'hidden' : null
                },
                deep: true
            }
        },
        components: {
            BaseModal: () => import(/* webpackChunkName: "/app/common/BaseModal" */ '@theme-views/common/BaseModal'),
            UserNotif: () => import(/* webpackChunkName: "/app/common/UserNotif" */ '@views/common/UserNotif'),
            RegisterFollowUpPopUp: () => import(/* webpackChunkName: "/app/user/partials/RegisterFollowUpPopUp" */ '@theme-views/user/partials/RegisterFollowUpPopUp'),
            PRegisterPopUp: () => import(/* webpackChunkName: "/app/public/partials/PRegisterCard/PopUp" */ '@theme-views/public/partials/PRegisterCard/PopUp'),
            LoginPopUp: () => import(/* webpackChunkName: "/app/public/pages/Login/PopUp" */ '@theme-views/public/pages/Login/PopUp'),
            ConfirmPopUp: () => import(/* webpackChunkName: "/app/user/partials/ConfirmPopUp" */ '@theme-views/user/partials/ConfirmPopUp'),
            PaymentPopUpOptions: () => import(/* webpackChunkName: "/app/user/partials/PaymentPopUpOptions" */ '@theme-views/user/partials/PaymentPopUpOptions'),
            PaymentBlikFillData: () => import(/* webpackChunkName: "/app/user/partials/PaymentBlikFillData" */ '@theme-views/user/partials/PaymentBlikFillData'),
            PaymentAccountDetails: () => import(/* webpackChunkName: "/app/user/partials/PaymentAccountDetails" */ '@theme-views/user/partials/PaymentAccountDetails'),
            PaymentFinish: () => import(/* webpackChunkName: "/app/user/partials/PaymentFinish" */ '@theme-views/user/partials/PaymentFinish'),
            PaymentModalCardTrustpay: () => import(/* webpackChunkName: "/app/user/partials/PaymentModal/CardTrustpay" */ '@theme-views/user/partials/PaymentModal/CardTrustpay'),
            PaymentModalBlikTrustpay: () => import(/* webpackChunkName: "/app/user/partials/PaymentModal/BlikTrustpay" */ '@theme-views/user/partials/PaymentModal/BlikTrustpay'),
            PaymentModalWireTrustpay: () => import(/* webpackChunkName: "/app/user/partials/PaymentModal/WireTrustpay" */ '@theme-views/user/partials/PaymentModal/WireTrustpay'),
            InactiveUserPopUp: () => import(/* webpackChunkName: "/app/user/partials/InactiveUserPopUp" */ '@theme-views/user/partials/InactiveUserPopUp'),
            MessagePopUp: () => import(/* webpackChunkName: "/app/user/partials/MessagePopUp" */ '@theme-views/user/partials/MessagePopUp'),
            MListCheckboxesPopUp: () => import(/* webpackChunkName: "/app/user/partials/mobile/MListCheckboxesPopUp" */ '@views/user/partials/mobile/MListCheckboxesPopUp'),
            ActivatePopUp: () => import(/* webpackChunkName: "/app/user/partials/ActivatePopUp" */ '@theme-views/user/partials/ActivatePopUp'),
            DeleteUserWithPromo: () => import(/* webpackChunkName: "/app/user/partials/DeleteUserWithPromo" */ '@theme-views/user/partials/DeleteUserWithPromo'),
            DeleteUserConfirmPopup: () => import(/* webpackChunkName: "/app/user/partials/DeleteUserConfirmPopUp" */ '@theme-views/user/partials/DeleteUserConfirmPopUp'),
            CompleteRegisterPopUp: () => import(/* webpackChunkName: "/app/user/partials/CompleteRegisterPopUp" */ '@theme-views/user/partials/CompleteRegisterPopUp'),
            CompletedRegisterPopUp: () => import(/* webpackChunkName: "/app/user/partials/CompletedRegisterPopUp" */ '@theme-views/user/partials/CompletedRegisterPopUp'),
            BoostAccountPopUp: () => import(/* webpackChunkName: "/app/user/partials/BoostAccountPopUp" */ '@theme-views/user/partials/BoostAccountPopUp'),
            BoostedProfilePopUp: () => import(/* webpackChunkName: "/app/user/partials/BoostedProfilePopUp" */ '@theme-views/user/partials/BoostedProfilePopUp'),
            ClaimedDailyFreeIcebreakersPopUp: () => import(/* webpackChunkName: "/app/user/partials/ClaimedDailyFreeIcebreakersPopUp" */ '@theme-views/user/partials/ClaimedDailyFreeIcebreakersPopUp'),
            InactiveUserPromo: () => import(/* webpackChunkName: "/app/user/partials/InactiveUserPromo" */ '@theme-views/user/partials/InactiveUserPromo'),
            FreeIcebreakerPopUp: () => import(/* webpackChunkName: "/app/user/partials/FreeIcebreakerPopUp" */ '@theme-views/user/partials/FreeIcebreakerPopUp'),
            NotifyPopUp: () => import(/* webpackChunkName: "/app/user/partials/NotifyPopUp" */ '@theme-views/user/partials/NotifyPopUp'),
            DiscountPopUp: () => import(/* webpackChunkName: "/app/user/partials/DiscountPopUp" */ '@theme-views/user/partials/DiscountPopUp')
        }
    }
</script>

<style lang="scss">
@import '~sass/app.scss';

// .app-container {

// }
</style>
